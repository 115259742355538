import { AppDownloadButton } from "@/common/components/AppDownloadButton";
import { ANDROID_LINK, IOS_LINK } from "@/common/static/apps.static";
import { Disclosure } from "@headlessui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { NoSsr } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { ReactNode } from "react";
import { chunk } from "lodash-es";
import MarkdownToJSX from "markdown-to-jsx";
import ChevronDown from "@whoppah/icons/24/ChevronDown";
import { Shimmer as ShimmerIcon } from "@whoppah/icons/24/Shimmer";
import SparkIcon from "@whoppah/icons/24/Spark";
import { Support } from "@whoppah/icons/24/Support";
import { FacebookFilled as FacebookIcon } from "@whoppah/icons/24/social/FacebookFilled";
import InstagramIcon from "@whoppah/icons/24/social/Instagram";
import LinkedInIcon from "@whoppah/icons/24/social/LinkedIn";
import Link from "@whoppah/next/link";
import { Button } from "@whoppah/ui/cva/Button";
import Rating from "@whoppah/ui/Rating";
import Input from "@whoppah/ui/input";
import useSignupForNewsletter from "Apollo/hooks/useSignupForNewsletter";
import { useAuth } from "auth";
import Image from "@/components/common/Image";
import GoogleReviewsPreval from "scenes/auth/register/preval/GoogleReviews.preval";
import zendesk from "../../../lib/zendesk";
import googleImage from "../../../src/assets/images/google-logo-full.png";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { WhoppahFullSize } from "@/assets/icons/Logo";
import { PAYMENT_METHODS } from "common/static/checkout/payment.static";
import {
  INSTAGRAM_URL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  AWARDS,
} from "@/common/static/footer.static";
import { EMAIL } from "@/scenes/auth/register/Constants";
import { Body } from "@whoppah/ui/typography/Body";
import { Heading } from "@whoppah/ui/cva/Heading";
import { LinkButton } from "@whoppah/ui/next/LinkButton";
import { PAYMENT_ROUTE } from "@/common/static/routes.static";
import { LinkEntry } from "@/common/contracts/footer.contracts";
import { LINKS_MAP } from "@/common/static/footer.static";
import { cn } from "@/common/utils/css.utils";

const NewsLetterForm = () => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);
  const [signup, { loading }] = useSignupForNewsletter();

  const { register, handleSubmit, getValues, setValue } = useForm();

  const handleSubmitForm = async (data: Record<string, string>) => {
    const { email } = data;

    try {
      await signup({
        variables: {
          email,
        },
      });

      // /** Trigger `hp_newsletter_subscription` event. */
      analyticsService.trackHpNewsletterSubscription();

      setIsSent(true);
    } catch (ex) {
      console.log((ex as Error).message);
    }
  };

  const handleSubmitBtn = () => {
    const email = getValues(EMAIL);
    if (email) {
      setValue(EMAIL, email.trim());
    }
  };

  if (isSent) {
    return <Body as="span">{t("footer:newsletter-form-success-message")}</Body>;
  }

  return (
    <form className="flex gap-x-2" onSubmit={handleSubmit(handleSubmitForm)}>
      <Input
        color="brand"
        type="email"
        {...register(EMAIL)}
        placeholder={t("common:newsletter-signup_email_input_placeholder")}
      />
      <Button
        className="min-w-[50%]"
        loading={loading}
        type="submit"
        appearance="outlined"
        onClick={handleSubmitBtn}
      >
        {t("common:newsletter-signup_submit_button")}
      </Button>
    </form>
  );
};

type FooterLinkProps = {
  link: LinkEntry;
};

const FooterLink = ({ link }: FooterLinkProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const handleClick = () => {
    analyticsService.trackFooterNavigation(link.label);
  };

  const href = typeof link.path === "string" ? link.path : link.path[locale];

  if (link.excludeOnLocals?.includes(locale)) {
    return null;
  }

  return (
    <Link href={href} passHref prefetch={false} sourceLocale="en">
      <a
        className="font-body text-sm text-grey-700 hover:underline"
        onClick={handleClick}
        {...(link.externalLink && { target: "_blank" })}
      >
        {t(link.label)}
      </a>
    </Link>
  );
};

const FooterWhoppahInfo = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const handleSocialButtonClick = (platform: string) => () => {
    /** Trigger `hp_social_media_links` event. */
    analyticsService.trackHpSocialMediaLinks(platform);
  };

  return (
    <div className="flex flex-col space-y-4 text-[#585858]">
      <Link href="/">
        <a aria-label="Go to Whoppah Homepage">
          <WhoppahFullSize className="hidden w-[150px] text-brand-500  md:block" />
          <SparkIcon className="-mb-3 block h-8 w-8 text-brand-500 md:hidden" />
        </a>
      </Link>
      <Body as="p">{t("common:support-work-time")}</Body>
      <LinkButton
        size="large"
        appearance="outlined"
        rightIcon={<Support />}
        href={zendesk.getUrl(locale)}
        className="self-start rounded-full"
      >
        {t("common:visit-support-center")}
      </LinkButton>
      <span className="flex gap-x-5">
        <Link href={FACEBOOK_URL}>
          <a
            aria-label="Go to Whoppah Facebook Page"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={handleSocialButtonClick("facebook")}
          >
            <FacebookIcon className="h-6 w-6 text-brand-500" />
          </a>
        </Link>
        <Link href={INSTAGRAM_URL}>
          <a
            aria-label="Go to Whoppah Facebook Page"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={handleSocialButtonClick("instagram")}
          >
            <InstagramIcon className="text-brand-500" />
          </a>
        </Link>
        <Link href={LINKEDIN_URL}>
          <a
            aria-label="Go to Whoppah Facebook Page"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={handleSocialButtonClick("linkedin")}
          >
            <LinkedInIcon className="text-brand-500" />
          </a>
        </Link>
      </span>
    </div>
  );
};

const FooterNewsletter = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isLoggedIn } = useAuth();

  const handleClick = () => {
    router.push({
      pathname: "/account/signup",
      query: {
        returnUrl: router.asPath,
      },
    });
  };

  return (
    <div className={cn("flex flex-col space-y-4", className)}>
      <Heading as="h5" className="text-brand-500">
        {t("footer:footer-newsletter")}
      </Heading>
      <NewsLetterForm />
      <NoSsr>
        {!isLoggedIn ? (
          <>
            <Heading as="h5" className="text-brand-500">
              {t("footer:footer-get-deals")}
            </Heading>
            <Button
              size="large"
              leftIcon={<ShimmerIcon />}
              onClick={handleClick}
            >
              {t("footer:footer-create-account-btn")}
            </Button>
          </>
        ) : null}
      </NoSsr>
    </div>
  );
};

type FooterProps = {
  backgroundColor?: string;
  className?: string;
};

export const Footer = ({ backgroundColor, className }: FooterProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const locale = router.locale;

  const { rating } = GoogleReviewsPreval[locale];

  return (
    <footer
      style={{ backgroundColor: backgroundColor }}
      className={cn(
        "pb-[74px] md:pb-0",
        backgroundColor ? `bg-${backgroundColor}` : "bg-[#F0F5F0]",
        className
      )}
    >
      <div className="px-6 py-6 lg:px-12 xl:px-24">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-[auto_1fr]">
          <div className="flex flex-col space-y-8 md:max-w-80">
            <FooterWhoppahInfo />
            <FooterNewsletter className="hidden md:flex" />
          </div>
          <div className="hidden grid-cols-1 gap-8 md:grid md:grid-cols-[1fr_1fr_1fr_1fr]">
            {LINKS_MAP.map(value => (
              <div
                key={`desktop-${value.title}`}
                className="flex flex-col space-y-3"
              >
                <Heading as="h5" className="text-brand-500">
                  {t(value.title)}
                </Heading>
                {value.links.map(link => (
                  <FooterLink key={`desktop-${link.label}`} link={link} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr className="border border-t-[2px] border-[#C0D7CA]/[.4] md:hidden" />
      {LINKS_MAP.map(value => (
        <Fragment key={`mobile-${value.title}`}>
          <div className="px-6 md:hidden lg:px-12 xl:px-24">
            <Disclosure as="div" className="md:hidden">
              {({ open }) => (
                <div>
                  <Disclosure.Button className="flex w-full items-center gap-x-2 py-4 text-left focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <Heading as="h5" size="h4" className="text-brand-500">
                      {t(value.title)}
                    </Heading>
                    <ChevronDown
                      className={cn(
                        "block h-6 w-6 shrink-0 text-brand-500",
                        open ? "rotate-180 transform" : ""
                      )}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel
                    unmount={false}
                    className="pb-6 font-body text-sm"
                  >
                    <div className="flex flex-col gap-y-4">
                      {value.links.map(link => (
                        <FooterLink key={`mobile-${link.label}`} link={link} />
                      ))}
                    </div>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          </div>
          <hr className="border border-t-[2px] border-[#C0D7CA]/[.4] md:hidden" />
        </Fragment>
      ))}
      <hr className="hidden border border-t-[2px] border-[#C0D7CA]/[.4] md:block" />
      <div className="grid grid-cols-2 items-center gap-y-4 px-6 py-6 md:flex md:gap-x-14 lg:px-12 xl:px-24">
        {AWARDS.map(award => (
          <div
            key={award.title}
            className="w-min-full relative h-12 md:w-[160px]"
          >
            <Image
              src={award.image}
              alt={award.title + " image"}
              layout="fill"
              objectFit="contain"
            />
          </div>
        ))}
      </div>
      <hr className="border border-t-[2px] border-[#C0D7CA]/[.4] md:hidden" />
      <div className="px-6 py-6 md:hidden lg:px-12 xl:px-24">
        <FooterNewsletter />
      </div>
      <hr className="border border-t-[2px] border-[#C0D7CA]/[.4]" />
      <div className="flex flex-col-reverse justify-between gap-y-4 px-6 py-10 md:flex-row md:items-center lg:px-12 xl:px-24">
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0">
          <div className="flex justify-center space-x-2 md:-ml-2 md:justify-start">
            <AppDownloadButton
              href={IOS_LINK}
              device="ios"
              className="h-[40px] w-[124px] min-[380px]:h-[45px] min-[380px]:w-[140px] min-[768px]:h-[30px] min-[768px]:w-[100px]"
            />
            <AppDownloadButton
              href={ANDROID_LINK}
              device="android"
              className="h-[40px] w-[124px] min-[380px]:h-[45px] min-[380px]:w-[140px] min-[768px]:h-[30px] min-[768px]:w-[100px]"
            />
          </div>
          <div className="w-min-full md:w-min-auto block md:h-8">
            <Link href={PAYMENT_ROUTE}>
              <a className="flex flex-col space-y-2 md:mt-[2px] md:flex-row md:space-x-2 md:space-y-0">
                {chunk(PAYMENT_METHODS, 5).map(paymentMethodChunk => (
                  <div
                    key={`chunk-${paymentMethodChunk?.[0]?.name}`}
                    className="flex justify-center space-x-2"
                  >
                    {paymentMethodChunk.map(method => (
                      <div
                        key={method.name}
                        className="relative h-[28px] w-[42px] min-[430px]:h-[30px] min-[430px]:w-[45px]"
                      >
                        <Image
                          src={method.image}
                          alt={method.name}
                          layout="fill"
                          objectFit="contain"
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </a>
            </Link>
          </div>
          <p className="text-center font-body text-[14px] font-medium text-[#B0B0B0] md:hidden">
            {t("footer:footer-rights-reserved")} &middot; ©{" "}
            {new Date().getFullYear()} Whoppah
          </p>
        </div>
        <div className="flex flex-col gap-y-2 md:hidden lg:block lg:px-3">
          <span className="flex items-center gap-x-2">
            <span className="relative h-[30px] w-[70px] md:h-[30px] md:w-[80px]">
              <Image
                layout="fill"
                objectFit="contain"
                src={googleImage}
                alt="google logo"
              />
            </span>
            <span className="flex">
              <Rating value={rating} className="h-5 w-5" />
            </span>
          </span>
          <MarkdownToJSX
            options={{
              forceBlock: true,
              overrides: {
                p: {
                  component: Body,
                  props: {
                    as: "p",
                  },
                },
              },
            }}
          >
            {t("footer:google-reviews-text-footer", {
              rating,
            })}
          </MarkdownToJSX>
        </div>
      </div>
      <hr className="hidden border border-t-[2px] border-[#C0D7CA]/[.4] md:block" />
      <div className="hidden px-6 py-6 md:block lg:px-12 xl:px-24">
        <p className="font-body text-sm font-medium text-[#B0B0B0]">
          {t("footer:footer-rights-reserved")} &middot; ©{" "}
          {new Date().getFullYear()} Whoppah
        </p>
      </div>
    </footer>
  );
};
